<template>
  <div class="add-user-wrapper">
    <Modal @execute="execute" :disabled-right-button="!isSubmitButtonEnabled">
      <template #content>
        <FormWrapper :send-form-data="validateForm" :mode="3" ref="addUserForm">
          <template #form-content>

            <h3 class="section-title">User details</h3>
            <!-- First name -->
            <BasicInputField field-title="First name"
                             field-name="firstName"
                             rules="required"
                             type="text"
                             placeholder="First name"
                             class="form-element"/>
            <!-- Last name -->
            <BasicInputField field-title="Last name"
                             field-name="lastName"
                             rules="required"
                             type="text"
                             placeholder="Last name"
                             class="form-element"/>
            <!-- Email -->
            <BasicInputField field-title="Email address"
                             field-name="email"
                             rules="required"
                             type="email"
                             placeholder="example@email.com"
                             class="form-element"/>

            <!-- Permission -->
            <h3 class="section-title">Permission</h3>
            <YoDropdown :dropdown-options="dropdownOptions"
                        field-name="roleId"
                        :placeholder="dropdownOptions[0].name"
                        :callback="() => {}"
                        class="form-element"/>

            <p class="text-error form-error">{{apiError}}</p>
          </template>
        </FormWrapper>

      </template>
    </Modal>
  </div>
</template>

<script>
import FormWrapper from "@/components/yo-form/FormWrapper";
import BasicInputField from "@/components/yo-form/BasicInputField";
import Modal from "@/components/partials/Modal";
import {computed, onUnmounted, ref} from "@vue/composition-api";
import {CREATE_USER} from "@/store/user/actions";
import YoDropdown from "@/components/yo-form/YoDropdown";
import {USER_ROLES} from "@/models/users/User";
import {getModalHelpers} from "@/composables/modalHelper";
import {RESET_API_ERROR} from "@/store/mutations";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";

export default {
  name: "AddUser",
  components: {
    YoDropdown,
    Modal,
    BasicInputField,
    FormWrapper
  },
  emits: ['added'],
  setup(props, {root, emit}) {
    const store = root.$store;

    const apiError = computed(() => store.getters.getApiError);

    onUnmounted(() => {
      store.commit(RESET_API_ERROR);
    })

    /** Modal **/
    const {closeModal} = getModalHelpers();

    /** Form **/
    const addUserForm = ref(null);

    function validateForm(formData) {
      const data = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        roleId: dropdownOptions.find(option => option.name === formData.roleId).id, // Get the id of the role, since the formData saved the name.
      }

      store.commit(RESET_API_ERROR);

      createUser(data).then(response => {
        if (response) {
          ToastHandler.addNew(TOAST_CONTENTS.USER_ADDED(`${formData.firstName} ${formData.lastName}`));
          emit('added');
          closeModal();
        }
      })
    }

    function execute() {
      addUserForm.value.handleSubmit(validateForm);
    }

    function createUser(data) {
      return store.dispatch(CREATE_USER, data);
    }

    /** Buttons **/
    const isSubmitButtonEnabled = computed(() => !addUserForm.value?.isInvalid);

    /** Dropdown **/
    const dropdownOptions = [
      {
        id: null,
        name: '-Select permission-'
      },
      {
        id: USER_ROLES.ADMIN,
        name: 'Admin'
      },
      {
        id: USER_ROLES.SUPER_ADMIN,
        name: 'Super admin'
      },
      {
        id: USER_ROLES.READ_ONLY,
        name: 'Read only'
      },
    ]

    return {
      apiError,

      /** Form **/
      addUserForm,
      validateForm,
      execute,

      /** Buttons **/
      isSubmitButtonEnabled,

      /** Dropdown **/
      dropdownOptions,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.add-user-wrapper {
  .section-title {
    font-size: rem(17);
    font-weight: var(--montserrat-weight-bold);
    line-height: rem(20);
    margin-bottom: rem(18);
  }

  .form-element {
    margin-bottom: rem(30);
  }

  .form-error {
    margin-bottom: rem(30);
  }
}
</style>