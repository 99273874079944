<template>
  <div class="head-filter-wrapper">
    <div @click="toggleFilter" class="filter-toggle">
      <p class="selected-filter">: {{ activeOption.text }}</p>
      <img src="../../../assets/icons/svg/ic_dropdown_active.svg" :class="{'caret-up': isFilterOpen}" class="caret-icon" alt="caret"/>
    </div>

    <!-- Mask - Used to close the dropdown, if the user clicks outside of it. -->
    <div v-if="isFilterOpen" @click="toggleFilter" class="options-mask"/>

    <!-- Filter options -->
    <div v-if="isFilterOpen" class="filter-options">
      <div v-for="option in filterOptions"
           :key="option.id"
           @click="selectOption(option)"
           :class="{'selected': option.id === activeOption.id}"
           class="option">
        <span class="text">{{ option.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "@vue/composition-api";

export default {
  name: "HeadFilter",
  props: {
    // Array of FilterOption
    filterOptions: {
      type: Array
    },
    activeOption: {}
  },
  emits: ['filter'],
  setup(props, {emit}) {
    const isFilterOpen = ref(false);

    function toggleFilter() {
      isFilterOpen.value = !isFilterOpen.value;
    }

    function selectOption(option) {
      emit('filter', option);
      toggleFilter();
    }

    return {
      isFilterOpen,
      toggleFilter,

      selectOption,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.head-filter-wrapper {
  display: inline-flex;
  position: relative;
}

// Toggle
.filter-toggle {
  @include hover-active-pointer;
  display: inline-flex;

  .selected-filter {
    font-family: var(--open-sans);
    font-weight: normal;
    margin-right: rem(6);
  }

  .caret-icon {
    height: rem(24);
    width: rem(24);
    transition: 0.2s ease-in-out;

    &.caret-up {
      transform: rotate(180deg);
    }
  }
}

// Mask
.options-mask {
  @include position-elevation(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0, $elevation: 1);
  background-color: transparent;

  @include on-hover {
    cursor: default;
  }
}

// Dropdown
.filter-options {
  @include position-elevation(absolute, $top: calc(100% + 13px), $right: 0, $elevation: 2);
  background-color: white;
  border: rem(1) solid var(--gray-light-04);
  border-radius: rem(4);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.25);
  font-weight: normal;
  padding: rem(8);
  width: rem(173);
}

.option {
  border-radius: rem(5);
  padding: rem(8) 0 rem(8) rem(15);

  .text {
    font-family: var(--open-sans);
  }

  &.selected {
    background-color: var(--gray-main);
    padding-left: rem(16);

    .text {
      font-weight: var(--open-sans-weight-bold);
    }
  }

  @include hover-active-pointer {
    background-color: var(--gray-main);

    .text {
      font-weight: var(--open-sans-weight-bold);
    }

    .button-change {
      display: flex;
    }
  }
}
</style>