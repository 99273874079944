<template>
  <div class="users-overview">

    <NoRecordsYet v-if="users.length === 0 && !searchTerm && !activeFilterRole"
                  @create="$emit('create')"
                  :button-icon="require('../../assets/icons/svg/ic_add_white.svg')"
                  title="No users have been added yet"
                  text="Press the button below to add new<br/>users to Billy."
                  button-text="Add user"/>

    <div v-if="users.length > 0 || searchTerm || activeFilterRole" class="page-top">
      <h1 class="main-title">Users</h1>

      <!-- Add user button -->
      <DButton @click.native="setModal(MODAL_CONTENT.CREATE_USER)"
               :icon="require('../../assets/icons/svg/ic_add_white.svg')"
               text="Add user"
               type="button"
               class="button-main add-user"/>
    </div>

    <!-- Search and bulk actions -->
    <div v-if="users.length || searchTerm || activeFilterRole" class="search-actions-wrapper">
      <SearchInputField @search="performSearch"
                        :includeSuggestions="false"
                        :result-count="searchTerm ? users.length : null"
                        id="userSearch"
                        placeholder="Search for a user"
                        class="search"/>
      <UsersBulkActions :users="checkboxController.selectedRows"
                        @deleted="resetAndRefreshUsers"
                        @role-updated="resetAndRefreshUsers"/>
    </div>

    <!-- Users' table -->
    <CustomTable :options="tableOptions"
                 :table-data="users"
                 :selected-rows="checkboxController.selectedRows"
                 :bordered-cell="editableCellHandler.cell"
                 :sorting-function="sortUsers"
                 class="users-table">
      <!-- Head -->
      <template #headcheckbox>
        <BasicCheckbox id="checkbox-head"
                       :is-checkbox-checked="checkboxController.isCheckboxAllChecked(users)"
                       :callback="() => selectAllRows()"/>
      </template>
      <template #headpermission>
        <HeadFilter :filter-options="filterUserOptions"
                    :active-option="activeFilterRole"
                    @filter="filterUsers"/>
      </template>

      <!-- Body -->
      <template #checkbox="props">
        <BasicCheckbox :id="`checkbox-${props.rowData.id}`"
                       :is-checkbox-checked="checkboxController.isCheckboxChecked(props.rowData.id)"
                       :callback="() => selectRow(props.rowData)"/>
      </template>
      <template #username="props">
        <EditableTableCell @save="({content}) => editUsername(content, props.rowData)"
                           @on-active-edit="updateActiveEditableCell"
                           @on-inactive-edit="updateActiveEditableCell"
                           cell-key="username"
                           :row-data="props.rowData"
                           :content-id="`username-${props.rowData.id}`"/>
      </template>
      <template #email="props">
        <EditableTableCell @save="({content}) => editUserEmail(content, props.rowData)"
                           @on-active-edit="updateActiveEditableCell"
                           @on-inactive-edit="updateActiveEditableCell"
                           cell-key="email"
                           :row-data="props.rowData"
                           :content-id="`email-${props.rowData.id}`"/>
      </template>
      <template #permission="props">
        <UserPermissionCell @edited="resetAndRefreshUsers"
                            @on-edit-change="updateActiveEditableCell"
                            :user="props.rowData"/>
      </template>
      <template #createdAt="props">
        {{ props.rowData.creationDate }}
      </template>
    </CustomTable>

    <!-- Add user -->
    <AddUser v-if="activeModal === MODAL_NAMES.CREATE_USER" @added="resetAndRefreshUsers"/>
  </div>
</template>

<script>
import getUserTableOptions from "@/utils/tables/userTableHelpers";
import BasicCheckbox from "@/components/elements/BasicCheckbox";
import CustomTable from "@/components/partials/table/CustomTable";
import DButton from "@/components/elements/DButton";
import AddUser from "@/components/partials/users/AddUser";
import {getModalHelpers} from "@/composables/modalHelper";
import {MODAL_CONTENT, MODAL_NAMES} from "@/models/ModalContent";
import Toast from "@/components/elements/Toast";
import UsersBulkActions from "@/components/partials/users/UsersBulkActions";
import SearchInputField from "@/components/partials/search/SearchInputField";
import UserPermissionCell from "@/components/partials/users/UserPermissionCell";
import EditableTableCell from "@/components/partials/table/EditableTableCell";
import {ref} from "@vue/composition-api";
import {EditableCellHandler} from "@/utils/helpers/editableCellHelpers";
import getUsers from "@/composables/users/getUsers";
import CheckboxController from "@/utils/controllers/CheckboxController";
import HeadFilter from "@/components/partials/table/HeadFilter";
import NoRecordsYet from "@/components/partials/NoRecordsYet";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";

export default {
  name: "UsersOverview",
  components: {
    NoRecordsYet,
    HeadFilter,
    EditableTableCell,
    UserPermissionCell,
    SearchInputField,
    UsersBulkActions,
    Toast,
    AddUser,
    DButton,
    CustomTable,
    BasicCheckbox,
  },
  setup(props, {root}) {
    const store = root.$store;

    /** Users **/
    const {filterUserOptions, defaultUserFilter, users, retrieveUsers, editUser} = getUsers();

    /** Modal **/
    const {activeModal, setModal} = getModalHelpers();

    /** Editable cells **/
    const editableCellHandler = ref(new EditableCellHandler());

    function updateActiveEditableCell(data) {
      editableCellHandler.value.addCell(data);
    }

    /** Checkboxes **/
    const checkboxController = ref(new CheckboxController());

    function selectRow(rowData) {
      checkboxController.value.selectRow(rowData);
    }

    function selectAllRows() {
      checkboxController.value.selectAllRows(users.value);
    }

    /** Search **/
    const searchTerm = ref("");

    function performSearch(term) {
      searchTerm.value = term;
      retrieveUsers(searchTerm.value, activeFilterRole.value.id, sortingOption.value);
    }

    /** Filter **/
    const activeFilterRole = ref(defaultUserFilter);

    function filterUsers(filterOption) {
      activeFilterRole.value = filterOption;
      retrieveUsers(searchTerm.value, activeFilterRole.value.id, sortingOption.value);
    }

    /** Sort **/
    const sortingOption = ref('');

    function sortUsers(option) {
      sortingOption.value = option.sortingType;
      retrieveUsers(searchTerm.value, activeFilterRole.value.id, sortingOption.value);
    }

    /** Users **/
    retrieveUsers(searchTerm.value, activeFilterRole.value.id, sortingOption.value);

    function resetAndRefreshUsers() {
      retrieveUsers(searchTerm.value, activeFilterRole.value.id, sortingOption.value); // Get the updated users.
      checkboxController.value.resetSelectedRows(); // Reset the selected rows.
    }

    /** Edit user **/
    async function editUserEmail(email, userData) {
      const data = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: email,
        roleId: userData.roleId
      };
      const edited = await editUser(userData.id, data);
      if (edited) {
        ToastHandler.addNew(TOAST_CONTENTS.USER_EMAIL_EDITED); // Display the success toast.
      }
    }

    async function editUsername(username, userData) {
      const separatedNames = username.split(/ (.*)/s); // Splits the string into 2 strings (first and last name), on the first space occurrence.
      const data = {
        firstName: separatedNames[0],
        lastName: separatedNames[1],
        email: userData.email,
        roleId: userData.roleId
      };
      const edited = await editUser(userData.id, data);
      if (edited) {
        ToastHandler.addNew(TOAST_CONTENTS.USERNAME_EDITED); // Display the success toast.
      }
    }

    return {
      /** Modal **/
      MODAL_CONTENT,
      MODAL_NAMES,
      activeModal,
      setModal,

      /** Editable cells **/
      editableCellHandler,
      updateActiveEditableCell,

      /** Checkboxes **/
      checkboxController,
      selectRow,
      selectAllRows,

      /** Search **/
      searchTerm,
      performSearch,

      /** Filter **/
      activeFilterRole,
      filterUsers,

      /** Sort **/
      sortUsers,

      /** Users **/
      filterUserOptions,
      defaultUserFilter,
      tableOptions: getUserTableOptions(),
      retrieveUsers,
      users,
      resetAndRefreshUsers,

      /** Edit user **/
      editUserEmail,
      editUsername,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.users-overview {
  text-align: left;
}

.page-top {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(65);

  .add-user {
    height: rem(52);
    padding: rem(17) rem(24);
  }
}

.search-actions-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(24);

  .search {
    flex-basis: 55%;
    max-width: rem(640);
  }
}
</style>