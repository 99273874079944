import {computed} from "@vue/composition-api";
import {EDIT_USER, GET_USERS} from "@/store/user/actions";
import vuexStore from "@/store";
import {USER_ROLES} from "@/models/users/User";
import FilterOption from "@/models/filter/FilterOption";

export default function getUsers() {
  const store = vuexStore;
  const filterUserOptions = [
    new FilterOption(0, 'All'),
    new FilterOption(USER_ROLES.SUPER_ADMIN, 'Super admin'),
    new FilterOption(USER_ROLES.ADMIN, 'Admin'),
    new FilterOption(USER_ROLES.READ_ONLY, 'Read only'),
  ];
  const defaultUserFilter = filterUserOptions[0];

  const users = computed(() => store.getters.getUsers);

  function retrieveUsers(searchTerm = "", roleId, sortOrder = '') {
    return store.dispatch(GET_USERS, {searchTerm, roleId, sortOrder});
  }

  function editUser(userId, userData) {
    return store.dispatch(EDIT_USER, {userId: userId, userData})
  }

  return {
    defaultUserFilter,
    filterUserOptions,
    users,
    retrieveUsers,
    editUser,
  }
}