<template>
  <div :class="{'dropdown-error': fieldError}" class="dropdown-wrapper">
    <div v-if="fieldTitle" class="dropdown-title-box">
      <p class="dropdown-title">{{ fieldTitle }}</p>
    </div>

    <div v-if="isDropdownShown" @click="toggleDropdown()" class="dropdown-mask"/>
    <div @click="toggleDropdown()" class="basic-dropdown">
      <div class="dropdown-text-container">
        <!-- Dropdown icon -->
        <img :src="dropdownIcon" class="caret-icon" alt=""/>
        <BasicInputField :field-title="''"
                         :field-name="fieldName"
                         :rules="'required'"
                         :type="'text'"
                         :is-read-only="true"
                         :placeholder="placeholder"
                         :value="selected ? selected.name : ''"
                         :debounce="0"
                         :api-error-text="apiErrorText"
                         class="dropdown-input"/>
      </div>
      <div v-if="isDropdownShown" id="option-list" class="dropdown-list">
        <div class="option-list-inner">
          <div v-for="option in dropdownOptions"
               :key="option.id"
               :class="{'selected-option': selected ? option.id === selected.id : false}"
               :id="`option-${option.id}`"
               @click="selectOption(option)"
               class="option">
            {{ option.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicInputField from "@/components/yo-form/BasicInputField";

export default {
  name: "YoDropdown",
  components: {
    BasicInputField
  },
  props: {
    dropdownOptions: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    fieldTitle: {
      type: String,
      required: false,
      default: undefined
    },
    fieldName: {
      type: String,
      required: true
    },
    selectedOption: {
      required: false
    },
    callback: {
      type: Function,
      required: true
    },
    fieldError: {
      type: Boolean,
      required: false,
      default: false
    },
    enableKeyboardListeners: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    isDropdownShown: false,
    selected: undefined,

    apiErrorText: '',
  }),
  watch: {
    selectedOption: function (newVal) {
      if (newVal) {
        this.selected = newVal;
      }
    }
  },
  computed: {

    /** Dropdown icon **/
    dropdownIcon: function () {
      return this.isDropdownShown ? require('../../assets/icons/svg/ic_dropdown_active.svg') : require('../../assets/icons/svg/ic_dropdown_inactive.svg');
    }
  },
  methods: {
    toggleDropdown: function () {
      this.isDropdownShown = !this.isDropdownShown;
    },
    selectOption(option) {
      if (!option.id) {
        return;
      }
      this.selected = option;
      this.$props.callback(option);
    },
    setValues: function () {
      if (!this.selectedOption) {
        this.$props.callback(this.selected);
      } else {
        this.selected = this.selectedOption;
      }
    },
  },
  created() {
    this.setValues();
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";
@import "../../assets/css/base.dropdown";

.dropdown-mask {
  @include position-elevation(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0, $elevation: 1);
  background-color: transparent;
}

.dropdown-input {
  margin-bottom: 0;
}

.basic-dropdown {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .dropdown-text-container {
    align-items: center;
    display: flex;
    height: rem(40);
    position: relative;
  }

  .option {
    background-color: white;
    padding: rem(8) rem(16);

    @include hover-active-pointer {
      border-radius: rem(10);
      background-color: var(--gray-main);
    }
  }

  .selected-option {
    font-weight: var(--open-sans-weight-bold);
    border-radius: rem(5);
    background-color: var(--gray-main);
  }
}

.caret-icon {
  @include position(absolute, $top: 50%, $right: rem(15));
  height: rem(24);
  transform: translateY(-50%);
  width: rem(24);
}

.dropdown-title {
  font-size: rem(14);
  line-height: rem(18);
  margin: rem(16) 0 rem(8) 0;
  padding-left: rem(14);
}

.dropdown-error {
  .basic-dropdown {
    border: 1px solid var(--red-main);
  }

  .dropdown-title, .dropdown-text {
    color: var(--red-main);
  }
}
</style>
