<template>
  <div class="user-permission-cell">
    <p @click="toggleOptions" class="cell-value">{{ selectedPermission.text }}</p>

    <!-- Mask - Used to close the dropdown, if the user clicks outside of it. -->
    <div v-if="areOptionsOpen" @click="toggleOptions" class="options-mask"/>

    <!-- Options -->
    <div v-if="areOptionsOpen" class="permission-options">
      <div v-for="option in permissionOptions"
           :key="option.id"
           @click="selectPermission(option)"
           :class="{'selected': option.id === selectedPermission.id}"
           class="permission-option">
        <span class="text">{{ option.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {USER_ROLES} from "@/models/users/User";
import {computed, ref} from "@vue/composition-api";
import {EDIT_USER} from "@/store/user/actions";
import getUsers from "@/composables/users/getUsers";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";

const cellKey = 'permission';

export default {
  name: "UserPermissionCell",
  emits: ['edited', 'on-edit-change', 'on-inactive-edit'],
  props: {
    user: {}
  },
  setup(props, {root, emit}) {
    const store = root.$store;
    const permissionOptions = [
      {
        id: USER_ROLES.SUPER_ADMIN,
        text: 'Super admin',
      },
      {
        id: USER_ROLES.ADMIN,
        text: 'Admin',
      },
      {
        id: USER_ROLES.READ_ONLY,
        text: 'Read only',
      }
    ];

    /** Toggling **/
    const areOptionsOpen = ref(false);
    const toggleOptions = () => {
      areOptionsOpen.value = !areOptionsOpen.value;

      if (areOptionsOpen.value) {
        emit('on-edit-change', {cellKey, rowId: props.user.id});
      } else {
        emit('on-edit-change', {cellKey: null, rowId: props.user.id});
      }
    };

    /** Selection **/
    const selectedPermission = computed(() => permissionOptions.find(option => option.id === props.user.roleId));

    async function selectPermission(permission) {
      const userData = {
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        email: props.user.email,
        roleId: permission.id
      };
      const edited = await editUser(props.user.id, userData);
      if (edited) {
        ToastHandler.addNew(TOAST_CONTENTS.USER_SINGLE_ROLE_UPDATED(permission.text));
        emit('edited', permission.text);
        toggleOptions();
      }
    }

    /** Users **/
    const {editUser} = getUsers();

    return {
      /** Toggling **/
      areOptionsOpen,
      toggleOptions,

      /** Selection **/
      permissionOptions,
      selectedPermission,
      selectPermission
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.user-permission-cell {
  position: relative;

  .cell-value {
    font-family: var(--open-sans);
  }
}


.options-mask {
  @include position-elevation(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0, $elevation: 1);
  background-color: transparent;

  @include on-hover {
    cursor: default;
  }
}

.permission-options {
  @include position-elevation(absolute, $top: calc(100% + 13px), $right:0, $left: 0, $elevation: 2);
  background-color: white;
  border: rem(1) solid var(--gray-light-04);
  border-radius: rem(4);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.25);
  padding: rem(16) rem(8) rem(21) rem(8);
}

.permission-option {
  border-radius: rem(5);
  padding: rem(8) 0 rem(8) rem(15);

  .text {
    font-family: var(--open-sans);
  }

  &.selected {
    background-color: var(--gray-main);
    padding-left: rem(16);

    .text {
      font-weight: var(--open-sans-weight-bold);
    }
  }

  @include hover-active-pointer {
    background-color: var(--gray-main);

    .text {
      font-weight: var(--open-sans-weight-bold);
    }

    .button-change {
      display: flex;
    }
  }
}
</style>