import {KEYS_SORTING, SORTING_TYPES, SortingOption} from "@/models/SortingHandler";

export default function getUserTableOptions() {
  const tableOptions = {
    headings: {
      'checkbox': '',
      'username': 'Name',
      'email': 'Email address',
      'permission': 'Permission',
      'createdAt': 'Added',
    },
    editable: {
      'checkbox': false,
      'username': true,
      'email': true,
      'permission': true,
      'createdAt': false,
    },
    sortable: {
      'checkbox': false,
      'username': false,
      'email': false,
      'permission': false,
      'createdAt': [
        new SortingOption(20, KEYS_SORTING.CREATED_AT, SORTING_TYPES.DESCENDING),
        new SortingOption(10, KEYS_SORTING.CREATED_AT, SORTING_TYPES.ASCENDING),
      ]

    },
  };

  return tableOptions;
}