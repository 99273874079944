<template>
  <div class="bulk-actions-wrapper">
    <!-- Prompt text on the left of the div. -->
    <p v-if="!users.length" class="select-users-prompt">Please select user(s)</p>
    <p v-else class="select-users-prompt">
      <span class="user-count">{{ users.length }}</span> {{ users.length > 1 ? 'users' : 'user' }} selected
    </p>

    <!-- Mask - Used to close the dropdown, if the user clicks outside of it. -->
    <div v-if="areActionsDisplayed" @click="toggleActions" class="bulk-actions-mask"/>

    <!-- Toggle -->
    <div class="actions-toggle">

      <div @click="toggleActions" :class="[{'active-toggle': areActionsDisplayed}]" class="toggle-box">
        <p class="placeholder">Bulk Actions</p>
        <img src="../../../assets/icons/svg/ic_dropup_active.svg" class="caret-icon" alt="caret up"/>
      </div>


      <!-- Actions -->
      <div v-if="areActionsDisplayed" class="action-options-box">

        <!-- Permission -->
        <div class="option-section">
          <p class="section-title">Permission</p>

          <div v-for="option in permissionOptions" :key="option.id" class="action-option">
            <span class="option-text">{{ option.text }}</span>

            <!-- Change button -->
            <DButton @click.native="changeUserRole(option)"
                     text="Change"
                     :icon="require('../../../assets/icons/svg/ic_next_active_white.svg')"
                     type="button"
                     class="button-main button-change"/>
          </div>
        </div>

        <div class="divider-line actions-divider"></div>

        <!-- Delete -->
        <div @click="displayDeleteWarning" class="delete-users">
          <img src="../../../assets/icons/svg/ic_delete.svg" class="icon" alt="delete"/>
          <span class="text">Delete user(s)</span>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <Modal v-if="activeModal === MODAL_NAMES.DELETE_USERS"/>
  </div>
</template>

<script>
import {USER_ROLES} from "@/models/users/User";
import DButton from "@/components/elements/DButton";
import {DELETE_USERS, SET_USER_ROLE} from "@/store/user/actions";
import {getModalHelpers} from "@/composables/modalHelper";
import {MODAL_CONTENT, MODAL_NAMES} from "@/models/ModalContent";
import Modal from "@/components/partials/Modal";
import {computed, ref} from "@vue/composition-api";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";

export default {
  name: "UsersBulkActions",
  components: {
    Modal,
    DButton
  },
  props: {
    users: {}
  },
  emits: ['deleted'],
  setup(props, {root, emit}) {
    const store = root.$store;
    const userIds = computed(() => props.users.map(user => user.id));

    /** Action toggling **/
    const areActionsDisplayed = ref(false);

    function toggleActions() {
      // If there are no selected users, the actions should not be opened.
      if (!props.users.length) {
        return;
      }
      areActionsDisplayed.value = !areActionsDisplayed.value;
    }

    /** Modal **/
    const {activeModal, setModal, closeModal} = getModalHelpers();

    /** Permission **/
    async function changeUserRole(role) {
      const updated = await setUsersRole(role.id);
      if (updated) {
        const count = userIds.value.length;
        const roleText = role.text;
        ToastHandler.addNew(TOAST_CONTENTS.USER_ROLE_UPDATED(count, roleText));
        emit('role-updated', {count, role});
        toggleActions();
      }
    }

    function setUsersRole(roleId) {
      return store.dispatch(SET_USER_ROLE, {userIds: userIds.value, roleId})
    }

    /** Delete **/
    function displayDeleteWarning() {
      const count = props.users.length;
      setModal(MODAL_CONTENT.DELETE_USERS(count, deleteUsers));
    }

    async function deleteUsers() {
      const deleted = await performDelete();
      if (deleted) {
        ToastHandler.addNew(TOAST_CONTENTS.USERS_DELETED(userIds.value.length));
        emit('deleted', userIds.value.length);
        closeModal();
        toggleActions();
      }
    }

    function performDelete() {
      return store.dispatch(DELETE_USERS, userIds.value);
    }

    return {
      permissionOptions: [
        {
          id: USER_ROLES.SUPER_ADMIN,
          text: 'Super admin',
        },
        {
          id: USER_ROLES.ADMIN,
          text: 'Admin',
        },
        {
          id: USER_ROLES.READ_ONLY,
          text: 'Read only',
        }
      ],

      /** Action toggling **/
      areActionsDisplayed,
      toggleActions,

      /** Modal **/
      MODAL_NAMES,
      activeModal,

      /** Permission **/
      changeUserRole,

      /** Delete **/
      displayDeleteWarning,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.bulk-actions-wrapper {
  align-items: center;
  display: flex;
}

// Text
.select-users-prompt {
  color: var(--gray-light-03);
  font-family: var(--open-sans);
  font-size: rem(16);
  margin-right: rem(24);

  .user-count {
    color: var(--red-main);
    font-size: rem(17);
    font-weight: bold;
    line-height: rem(20);
  }
}

// Actions toggle
.actions-toggle {
  position: relative;
}

.toggle-box {
  @include hover-active-pointer();
  align-items: center;
  background-color: var(--gray-light-a);
  border: rem(1) solid var(--gray-light-04);
  border-radius: rem(3);
  display: flex;
  height: rem(52);
  padding: rem(16) rem(15);

  .placeholder {
    color: var(--gray-light);
    font-family: var(--open-sans);
  }

  .caret-icon {
    height: rem(24);
    margin-left: rem(64);
    opacity: 0.3;
    transform: rotate(180deg);
    transition: 0.25s ease-in-out;
    width: rem(24);
  }

  &.active-toggle {
    border: rem(1) solid var(--black-light);
    border-radius: rem(3);
    box-shadow: inset 0 rem(1) rem(3) 0 rgba(255, 255, 255, 0);

    .placeholder {
      color: var(--black-light);
    }

    .caret-icon {
      opacity: 1;
      transform: rotate(0);
    }
  }
}

.bulk-actions-mask {
  @include position-elevation(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0, $elevation: 1);
  background-color: transparent;
}

// Action options
.action-options-box {
  @include position-elevation(absolute, $top: calc(100% + 8px), $right: 0, $elevation: 2);
  background-color: white;
  border: rem(1) solid var(--gray-light-04);
  border-radius: rem(4);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.25);
  padding: rem(16) rem(8) rem(21) rem(8);
  width: rem(320);
}

// Option section
.option-section {
  .section-title {
    color: var(--gray-light-03);
    font-size: rem(17);
    font-weight: var(--montserrat-weight-bold);
    line-height: rem(20);
    margin-bottom: rem(24);
    padding-left: rem(16);
  }
}

.action-option {
  display: flex;
  border-radius: rem(5);
  padding: rem(8) 0 rem(8) rem(15);

  &-text {
    font-family: var(--open-sans);
  }

  &.selected {
    background-color: var(--gray-main);
    padding-left: rem(16);

    .option-text {
      font-weight: var(--open-sans-weight-bold);
    }
  }

  @include hover-active-pointer {
    background-color: var(--gray-main);

    .option-text {
      font-weight: var(--open-sans-weight-bold);
    }

    .button-change {
      display: flex;
    }
  }
}

.button-change {
  display: none; // Should be visible only upon hover.
  font-size: rem(12);
  font-weight: var(--montserrat-weight-bold);
  height: rem(22);
  line-height: rem(15);
  margin-left: auto;
  padding: rem(3) rem(8);

  ::v-deep .button-icon {
    height: rem(16);
    margin-left: rem(3);
    width: rem(16);
  }
}

// Other
.actions-divider {
  margin-bottom: rem(26);
  margin-top: rem(19);
}

// Delete user
.delete-users {
  @include hover-active-pointer;
  display: inline-block;
  padding-left: rem(16);

  .text {
    color: #EF5350;
    font-family: var(--open-sans);
    vertical-align: middle;
  }

  .icon {
    height: rem(24);
    margin-right: rem(8);
    vertical-align: middle;
    width: rem(24);
  }
}
</style>